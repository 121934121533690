import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import contact from "../images/contact.svg"

const Contact = () => (
  <Layout linkColor="#5388ff">
    <SEO title="Contact" />
    
    <div className="paddedBody">
    
    <h1 className="text-center relativeDown100 blue">We're ready to get started when you are.</h1>
    <div className="text-center contactSealWrapper"><img src={contact} id="contactSeal" /></div>
    <div className="contactWrapperWrapper">
    <div className="contactFormWrapper">
      
    <form name="contact" method="post" data-netlify="true" netlify-honeypot="bot-field">
    <input type="hidden" name="form-name" value="contact" />
    <p className="d-none">
    <label>This field is not for humans <input name="bot-field" /></label>
  </p>
  <div className="form-group">
    <input type="name" className="form-control" id="exampleInputPassword1" placeholder="Name (required)" required />
  </div>
  <div className="form-group">
    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email (required)" required/>
  </div>
  <div className="form-group">
    <input type="email" className="form-control" id="subject" aria-describedby="subject" placeholder="Subject"/>
  </div>
  <div className="form-group">
  <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Message"></textarea>
  </div>
  <div className="text-center"><button type="submit" className="btn btn-primary mt-5">Submit</button></div>
  </form>
  </div>
    </div>
  </div>
  </Layout>
)

export default Contact
